const SEO = [
	{
		page: "home",
		description:
		"",	
		keywords: ["null"],
	},

	{
		page: "about",
		description:
		"",	
		keywords: ["null"],
	},

	{
		page: "articles",
		description:
		"",
		keywords: ["null"],
	},

	{
		page: "projects",
		description:"",
		keywords: ["null"],
	},

	{
		page: "contact",
		description: "",
		keywords: ["null"],
	},
];

export default SEO;
