import { projects } from "./projects.js";
import { experiences } from "./experiences.js";

const INFO = {
	main: {
		title: "Uzayr S. Portfolio",
		name: "Uzayr S.",
		email: "uzayrsyed.04@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		//twitter: "https://twitter.com/",
		github: "https://github.com/busa8908",
		linkedin: "https://www.linkedin.com/in/uzayrsyed/",
		// instagram: "https://instagram.com/",
		//stackoverflow: "https://stackoverflow.com/",
		//facebook: "https://facebook.com/",
	},

	homepage: {
		title: "Computer Science Student and Researcher.",
		description:
		"Hi! I’m Uzayr, a sophomore at Clemson University, passionate about blending my academic pursuits with hands-on experience in technology and entrepreneurship. With a solid foundation in C++, C, Lua, Java, and JavaScript, I thrive in projects that challenge me to innovate and solve real-world problems. Currently, I’m leading back-end development for Clemson Fintech’s project framework, continuing my research with the SPRinT lab, where my team is developing cutting-edge wearable solutions for transportation safety. Outside the lab, I’m actively involved in Clemson Forge and the Entrepreneur Club, where I collaborate with peers on programming projects and explore entrepreneurial ventures. Whether I’m working on game development, researching new tech solutions, or networking with like-minded individuals, I’m always eager to learn, create, and make an impact. Let’s connect and build something amazing together!",
	},

	about: {
		title: "Uzayr S.",
		description:
		"Hi, I'm Uzayr, a Computer Science sophomore at Clemson University with a passion for programming, research, and entrepreneurship. I'm deeply involved in various projects, including leading back-end development for Clemson Fintech and contributing as an undergraduate researcher in the SPRinT lab, where I help develop cutting-edge transportation technologies using wearable devices. Over the summer, I had the chance to integrate a driver alert system into a high-fidelity driving simulator for our team's pilot studies. Outside of my academic and professional pursuits, I enjoy staying active at the gym, exploring the outdoors through hiking and fishing, and spending time with friends. Whether it's tackling a new coding project or a mountain trail, I love challenges that push me to grow and think creatively. I'm also passionate about collaborating with others, whether through Clemson Forge, peer programming, or just sharing experiences and ideas with like-minded people.",		
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: projects,

	experiences: experiences,
};

export default INFO;
